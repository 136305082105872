@import "~bootstrap/dist/css/bootstrap.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Abel", sans-serif;
}

//.navbar-brand {
//  padding: 7px 15px 2px 15px;
//}

.navbar-default {
  background-color: #fff;
  border: none;
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  padding: 4px 8px;
}

//.navbar-left .nav > li > a {
//  padding: 10px 0 10px 8px;
//}
